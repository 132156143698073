<template>
  <div data-e2e="delivery-info-section">
    <div
      v-if="$slots.timeframe"
      class="drops-list-item-timeframe"
      data-e2e="delivery-timeframe"
    >
      <slot name="timeframe" />
    </div>
    <div
      v-if="$slots.address || $slots.phone"
      class="drop-item-info-wrapper"
      data-e2e="customer-info-section"
    >
      <div
        v-if="$slots.address"
        class="drops-list-item-customer-address-wrapper"
      >
        <slot name="address" />
      </div>
      <slot name="phone" />
    </div>
    <div
      class="drops-list-item-note"
      data-e2e="drops-list-item-note"
      v-if="$slots.note"
    >
      <slot name="note" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.drops-list-item-timeframe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px 6px;
  font-size: 21px;
  letter-spacing: 0.015em;
  font-weight: 400;
  color: $default-text-color;
  font-style: normal;
  line-height: 24px;
  border-bottom: 0.5px solid #b6c4df;
}

.drop-item-info-wrapper {
  display: flex;
  flex-direction: row;
  padding: 7px 8px 6px;
  letter-spacing: 0.015em;
}

.drops-list-item-customer-address-wrapper {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.015em;
  flex-direction: column;
  font-style: normal;
  line-height: 26px;
}

.drops-list-item-note {
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 400;
  color: $input-color;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.015em;
}
</style>
